<template>
  <div class="data_report">
    <div class="data_report-head">
      <div class="block">
        <el-date-picker
          v-model="value2"
          type="daterange"
          align="right"
          unlink-panels
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          :default-time="['00:00:00', '00:00:00']"
          value-format="yyyy-MM-dd"
          @change="clearDate"
          :picker-options="pickerOptions"
        >
        </el-date-picker>
      </div>
      <div style="margin-left:20px">
        <el-button type="primary" icon="el-icon-search" @click="search"
          >搜索</el-button
        >
      </div>
    </div>
    <div class="data_report-box">
      <div class="alls">
        <div class="alls-heads">
          <div class="alls-item alls-item-color center">编号/姓名</div>
          <div class="alls-item alls-item-mini center" v-if="0">总计</div>
          <div class="alls-item">
            <div class="alls-item-name">充值</div>
            <div class="alls-item-type">
              <div class="alls-item-type-name">业绩</div>
              <div class="alls-item-type-name">提成</div>
            </div>
          </div>
          <div class="alls-item">
            <div class="alls-item-name">充次</div>
            <div class="alls-item-type">
              <div class="alls-item-type-name">业绩</div>
              <div class="alls-item-type-name">提成</div>
            </div>
          </div>
          <div class="alls-item">
            <div class="alls-item-name">项目</div>
            <div class="alls-item-type">
              <div class="alls-item-type-name">业绩</div>
              <div class="alls-item-type-name">提成</div>
            </div>
          </div>
          <div class="alls-item">
            <div class="alls-item-name">商品</div>
            <div class="alls-item-type">
              <div class="alls-item-type-name">业绩</div>
              <div class="alls-item-type-name">提成</div>
            </div>
          </div>
          <div class="alls-item">
            <div class="alls-item-name">售卡</div>
            <div class="alls-item-type">
              <div class="alls-item-type-name">业绩</div>
              <div class="alls-item-type-name">提成</div>
            </div>
          </div>
        </div>
        <div class="alls-box">
          <div class="alls-list" v-for="(item, index) in tab" :key="index">
            <div class="alls-item">({{ item.workID }}){{ item.name }}</div>
            <div class="alls-item" v-if="0">9999</div>
            <div class="alls-item">
              <div class="alls-item-num">{{ item.recharge_yeji }}</div>
              <div class="alls-item-num">{{ item.recharge_tc }}</div>
            </div>
            <div class="alls-item">
              <div class="alls-item-num">{{ item.card_recharge_yeji }}</div>
              <div class="alls-item-num">{{ item.card_recharge_tc }}</div>
            </div>
            <div class="alls-item">
              <div
                class="alls-item-num cursor-pointer"
                style="color: #3288FF;text-decoration: underline;"
                @click="handleShowPerformanceDetails(item.staff_id)"
                v-if="item.project_yeji > 0"
              >{{ item.project_yeji }}</div>
              <div class="alls-item-num" v-else>{{ item.project_yeji }}</div>
              <div class="alls-item-num">{{ item.project_tc }}</div>
            </div>
            <div class="alls-item">
              <div class="alls-item-num">{{ item.product_yeji }}</div>
              <div class="alls-item-num">{{ item.product_tc }}</div>
            </div>
            <div class="alls-item">
              <div class="alls-item-num">{{ item.card_yeji }}</div>
              <div class="alls-item-num">{{ item.card_tc }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <el-dialog title="项目业绩详情" custom-class="project-yeji-dialog" :visible.sync="dialogTableVisible">
      <div class="screen">
        <div class="staff-name">员工姓名：</div>
        <el-select v-model="staff_id" placeholder="请选择" @change="handleShowPerformanceDetails(staff_id)">
          <el-option
            v-for="item in options"
            :key="item.id"
            :label="item.name"
            :value="item.id">
          </el-option>
        </el-select>
        <el-date-picker
          v-model="paramsDate"
          type="daterange"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          value-format="yyyy-MM-dd"
          @change="handleShowPerformanceDetails(staff_id)"
        >
        </el-date-picker>
      </div>
      <el-table
        :data="tableData"
        border
        style="width: 100%">
        <el-table-column
          prop="project_name"
          label="项目类型/支付方式"
          min-width="150">
        </el-table-column>
        <el-table-column
          label="现金"
          min-width="80">
          <template slot-scope="scope">
            ￥{{scope.row.yeji[1]}}
          </template>
        </el-table-column>
        <el-table-column
          label="微信"
          min-width="80">
          <template slot-scope="scope">
            ￥{{scope.row.yeji[2]}}
          </template>
        </el-table-column>
        <el-table-column
          label="支付宝"
          min-width="80">
          <template slot-scope="scope">
            ￥{{scope.row.yeji[3]}}
          </template>
        </el-table-column>
        <el-table-column
          label="银联"
          min-width="80">
          <template slot-scope="scope">
            ￥{{scope.row.yeji[4]}}
          </template>
        </el-table-column>
        <el-table-column
          label="美团"
          min-width="80">
          <template slot-scope="scope">
            ￥{{scope.row.yeji[11]}}
          </template>
        </el-table-column>
        <el-table-column
          label="卡金"
          min-width="80">
          <template slot-scope="scope">
            ￥{{scope.row.yeji[5]}}
          </template>
        </el-table-column>
        <el-table-column
          label="耗卡"
          min-width="80">
          <template slot-scope="scope">
            ￥{{scope.row.yeji[6]}}
          </template>
        </el-table-column>
        <el-table-column
          prop="count"
          label="合计数量"
          min-width="80">
        </el-table-column>
      </el-table>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      tabPosition: "left",
      pickerOptions: {
        shortcuts: [
          {
            text: "最近一周",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近一个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近三个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      },
      value2: "",
      tab: [],
      options: [],
      paramsDate: '',
      staff_id: '',
      tableData: [],
      dialogTableVisible: false,
    };
  },
  methods: {
    /* 搜索 */
    search() {
      //   console.log(this.value2);
      if (!this.value2) {
        this.value2 = [];
      }
      if (!this.value2.length) {
        this.$message({
          message: "请选择日期",
          type: "warning",
        });
        return false;
      }
      this.getList();
    },
    /* 日期清除 */
    clearDate(val) {
      if (!val) {
        this.value2 = [];
        this.getList();
      }
    },
    /**
     * 获取店铺所有员工列表
     */
    getStoreStaffList() {
      const token = sessionStorage.getItem("token");
      this.$axios({
        href: "/api/app/meiye/user/store",
        data: {
          token
        },
      }).then((res) => {
        console.log('getStoreStaffListRes', res);
        if (res.data) {
          this.options = res.data;
        }
      }).catch((err) => {
        console.log('getStoreStaffListErr', err);
      });
    },
    /* 获取数据 */
    getList() {
      let date1 = null;
      let date2 = null;
      // let _this = this;
      if (this.value2.length) {
        date1 = this.value2[0];
        date2 = this.value2[1];
      }
      this.$axios({
        href: "/api/app/meiye/finance/staff_report_pc",
        data: { date1: date1, date2: date2 },
      }).then((res) => {
        console.log(res);
        this.tab = res.data.data.staffs;
      });
    },
    /**
     * 获取并展示业绩详情
     */
    handleShowPerformanceDetails(staff_id) {
      if (staff_id != this.staff_id) {
        this.staff_id = staff_id;
        this.paramsDate = '';
      }
      const date1 = this.paramsDate.length ? this.paramsDate[0] : '';
      const date2 = this.paramsDate.length ? this.paramsDate[1] : '';
      this.$axios({
        href: "/api/app/meiye/finance/staff_project_report",
        data: {
          staff_id,
          date1,
          date2
        }
      }).then((res) => {
        console.log('handleShowPerformanceDetailsRes', res);
        if (res && res.data && res.data.successful === 1) {
          const yejiList = res.data.data.yeji;
          this.tableData = yejiList;
          let total = {
            project_name: '合计',
            count: 0,
            yeji: {
              1: 0,
              2: 0,
              3: 0,
              4: 0,
              5: 0,
              6: 0,
              11: 0,
            }
          };
          yejiList.forEach(item => {
            total.count = total.count + (item.count * 100);
            total.yeji[1] = total.yeji[1] + (item.yeji[1] * 100);
            total.yeji[2] = total.yeji[2] + (item.yeji[2] * 100);
            total.yeji[3] = total.yeji[3] + (item.yeji[3] * 100);
            total.yeji[4] = total.yeji[4] + (item.yeji[4] * 100);
            total.yeji[5] = total.yeji[5] + (item.yeji[5] * 100);
            total.yeji[6] = total.yeji[6] + (item.yeji[6] * 100);
            total.yeji[11] = total.yeji[11] + (item.yeji[11] * 100);
          })
          total.count = total.count / 100;
          total.yeji[1] = total.yeji[1] / 100;
          total.yeji[2] = total.yeji[2] / 100;
          total.yeji[3] = total.yeji[3] / 100;
          total.yeji[4] = total.yeji[4] / 100;
          total.yeji[5] = total.yeji[5] / 100;
          total.yeji[6] = total.yeji[6] / 100;
          total.yeji[11] = total.yeji[11] / 100;
          this.tableData.push(total);
          this.dialogTableVisible = true;
        }
      }).catch((err) => {
        console.log('handleShowPerformanceDetailsErr', err)
      });
    }
  },
  mounted() {
    this.getStoreStaffList();
    this.getList();
  },
};
</script>

<style lang="scss" scoped>
.data_report {
  width: 100%;
  //   height: 100%;
  min-height: 100%;
  background: #fff;
  &-head {
    width: 100%;
    height: 80px;
    padding: 0 10px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    border-bottom: 1px solid #eee;
    margin-bottom: 35px;
    // background: red;
  }
  &-box {
    width: 100%;
    height: 100%;
    padding: 0 10px;
    box-sizing: border-box;
  }
}
.alls {
  // margin: 20px 0;
  width: 100%;
  height: 560px;
  &-head {
    width: 100%;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    span {
      display: inline-block;
      padding: 0 5px;
      cursor: pointer;
    }
    span:first-child {
      color: #8088a8;
    }
    &-color {
      color: #8088a8;
    }
  }
  &-heads,
  &-list {
    width: 100%;
    height: 60px;
    display: flex;
  }
  &-heads {
    background: #8088a8;
    color: #fff;
  }
  &-box {
    width: 100%;
    height: 617px;
    overflow-y: auto;
  }
  &-list {
    border-bottom: 1px solid #eee;
    transition: all 0.5s;
    // cursor: pointer;
  }
  &-list:nth-child(even) {
    background: #fafafa;
  }

  &-list:hover {
    background: #fafafa;
  }

  &-item {
    width: 100%;
    height: 100%;
    border-right: 1px solid #f2f2f2;
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    &-num {
      width: 50%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    &-num:nth-child(odd) {
      border-right: 1px solid #eee;
    }
  }
  &-item-color {
    // width: 102px;
    background: #f56d5a;
  }
  &-list &-item:first-child {
    font-weight: 800;
  }
  &-item:last-child {
    border: none;
  }
  &-heads &-item {
    display: block;
    text-align: center;
    border-right: 1px solid #a4abc5;
    // line-height: 60px;
    &-name {
      width: 100%;
      height: 50%;
      background: #6f7799;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    &-type {
      width: 100%;
      height: 50%;
      display: flex;

      //   border-top:1px solid #eee;
      &-name {
        width: 50%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      &-name:nth-child(odd) {
        border-right: 1px solid #a4abc5;
      }
    }
  }
  &-item-super {
    width: 100%;
    height: 100%;
  }
}
.center {
  display: flex !important;
  align-items: center;
  justify-content: center;
}
.m {
  padding: 0 5px;
}
</style>

<style lang="scss">
.project-yeji-dialog {
  .el-dialog__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 60px;
    padding: 0 20px 0 18px;
    border-bottom: 1px solid #F5F5F5;
    box-sizing: border-box;
    .el-dialog__title {
      font-size: 16px;
      font-weight: bold;
      color: #000000;
    }
    &btn {
      top: 22px;
    }
  }
  .el-dialog__body {
    padding: 0 20px 20px;
    box-sizing: border-box;
    .screen {
      display: flex;
      align-items: center;
      padding: 12px 0;
      .staff-name {
        font-size: 13px;
        color: #000000;
      }
      .el-select {
        margin-right: 10px;
      }
    }
    .el-table {
      th {
        color: #FFFFFF;
        background-color: #8088A8;
      }
    }
  }
}
</style>
